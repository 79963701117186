@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #B5CE45;
    --primary-color-dark: #393f17;
    --accent-color: #399d73;
    --primary-color-light: #c9d973;
    --dark: #020e14;
    --primary-color-darker: #9baf3b;
}

html, body {
    scroll-padding-top: 107px !important;
}

::selection {
    background-color: #c6e062;
}

.bg-primary, .bg-primary span, .bg-primary i {
    color: var(--dark);
}

.bg-primary-darker {
    background-color: var(--primary-color-darker);
}

.placeholder-white::placeholder {
    color: white;
}

.underline-primary {
    text-decoration-color: var(--primary-color) !important;
    text-decoration: underline !important;
}

.border-primary {
    border-color: var(--primary-color);
}

.bg-primary .text-gray {
    color: rgba(14, 14, 14, 0.75);
}

.bg-dark {
    background-color: var(--dark);
}

html {
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
    font-family: 'Baloo Tamma 2', cursive;
    text-transform: uppercase;
}

.text-6xl {
    margin-top: 30px;
}

.bg-primary-dark {
    background-color: var(--primary-color-dark);
}

.btn-primary {
    @apply py-2 px-4 text-white font-semibold rounded-xl shadow-md hover:cursor-pointer hover:drop-shadow-md;
    background-color: var(--primary-color);
}

.navbar-logo {
    height: 76px;
}

.navbar {
    z-index: 15000;
}

#navbar {
    background-color: rgba(2, 14, 20, 0);
}

#illustration {
    position: fixed;
    top: 0;
    z-index: -100;
}

#illustration img {
    position: fixed !important;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    aspect-ratio: initial;
}

@media screen and (min-width: 1200px) {
    #illustration img {
        object-position: bottom;
    }
}

.navbar-mobile-menu-active {
    top: 0;
    position: fixed !important;
}

.menu-items a {
    font-size: 1.2rem;
}

.mobile-menu-items {
    position: absolute;
    width: 100%;
    top: 15vh;
    text-align: center;
}

.mobile-menu-items a {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 28px;
}

.text-primary {
    color: var(--primary-color);
}

.text-accent {
    color: var(--accent-color);
}

.hover\:text-accent:hover {
    color: var(--accent-color);
}

.hover\:text-primary:hover {
    color: var(--primary-color);
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo-container h1 {
    font-size: 2rem;
    font-weight: 300;
}

.bg-primary {
    background: var(--primary-color);
}

.bg-accent {
    background: var(--accent-color);
}

.sphere {
    width: 600px;
}

.main-heading {
    font-weight: 100 !important;
    font-size: 11rem !important;
    text-transform: lowercase !important;
}

.invisible {
    visibility: hidden;
}

@media screen and (max-width: 600px) {
    .main-heading {
        font-weight: 100 !important;
        font-size: 7rem !important;
    }

    .sphere {
        width: 80%;
    }
}

.feature-1 {
    z-index: 10;
}

.feature-2 {
    background: var(--primary-color);
    transform: rotate(350deg);
    opacity: 0.6;
    margin-bottom: 80px;
    margin-top: -20px;
    margin-left: -10px !important;
}

.feature-3 {
    transform: rotate(340deg);
    background: var(--accent-color);
    opacity: 0.6;
    margin-bottom: 80px;
    margin-top: -20px;
    margin-left: -10px !important;
}

.mobile-menu {
    display: none;
}

.mobile-menu-active {
    backdrop-filter: blur(12px) saturate(30%) brightness(0.5);
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100;
    bottom: 0;
    display: block;
}

.darken {
    filter: brightness(100%);
    z-index: -1;
}

.footer-social-icon {
    font-size: 5rem;
}

.big-slash {
    background: var(--dark);;
    width: 10px;
    height: 80px;
    transform: rotate(45deg);
    margin-top: 40px;
    margin-bottom: 40px;
}

.divider {
    width: 500px;
    height: 2px;
    background-color: var(--primary-color);
}

.fa-star {
    color: var(--primary-color);
}

.text-primary-light {
    color: var(--primary-color-light);
}

.personal-mail {
    color: white !important;
    margin-top: 20px !important;
}

#stars-text {
    font-weight: bold;
}

#map {
    width: 100%;
    height: 400px;
}

#reviews {
    overflow-x: auto;
    padding-bottom: 40px;
}

#review-card {
    min-width: 400px;
}

@media screen and (max-width: 600px) {
    html, body {
        overflow-x: hidden;
    }

    #illustration img {
        margin-top: 0;
        height: 100vh;
        object-fit: cover;
        object-position: 80% 0;
    }

    .navbar-logo {
        height: 60px;
    }

    html, body {
        scroll-padding-top: 92px !important;
    }

    #reviews {
        overflow-x: hidden;
    }

    #reviews > * {
        width: 100% !important;
        max-width: initial !important;
    }
}

.product-logo {
    height: 120px !important;
    margin-bottom: 24px;
}

.products {
    margin-top: 180px;
}

.products .own-product {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.products .product-web {
    /*padding-top: 8px;*/
    /*padding-bottom: 8px;*/
    /*border-radius: 8px;*/
    /*border: 1px solid white;*/
    margin-bottom: 14px;
    color: white;
    text-decoration: underline;
}

.products img {
    margin: 0 auto 40px auto;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #07212c;
}

::-webkit-scrollbar-thumb {
    background: #b5ce45;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(181, 206, 69, 0.8);
}


/*#region Forms*/

.styled-form form {
    display: flex;
    flex-direction: column;
}

.styled-form form input, .styled-form form textarea {
    margin: 0 0 20px 0;
    border-radius: 0.4rem;
    padding: 10px;
    color: white;
}

.styled-form form input[type=submit] {
    margin: 0;
    background-color: var(--primary-color-dark);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 1.5rem;
    font-weight: 600;
}

.styled-form form input:focus:not([type=submit]), .styled-form form textarea:focus {
    outline: none;
    box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.31);
}

.styled-form form label {
    margin-bottom: 5px;
}

.styled-form form input.touched:invalid {
    border: 3px solid #e58c8c;
    background-color: #faebeb;
}

.styled-form form:invalid input[type=submit] {
    border: 2px dashed #888888 !important;
    /*pointer-events: none !important;*/
}

.styled-form form:invalid input[type=submit]:hover {
    background-position: 0 0 !important;
}

/*#endregion */
