*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-end-1 {
  grid-column-end: 1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-end-1 {
  grid-row-end: 1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mt-96 {
  margin-top: -24rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-32 {
  height: 8rem;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-16 {
  width: 4rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-3\/4 {
  width: 75%;
}

.w-full {
  width: 100%;
}

.flex-auto {
  flex: auto;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scroll-py-40 {
  scroll-padding-top: 10rem;
  scroll-padding-bottom: 10rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-auto {
  overflow: auto;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pt-28 {
  padding-top: 7rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black {
  --tw-shadow-color: #171717;
  --tw-shadow: var(--tw-shadow-colored);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:root {
  --primary-color: #b5ce45;
  --primary-color-dark: #393f17;
  --accent-color: #399d73;
  --primary-color-light: #c9d973;
  --dark: #020e14;
  --primary-color-darker: #9baf3b;
}

html, body {
  scroll-padding-top: 107px !important;
}

::selection {
  background-color: #c6e062;
}

.bg-primary, .bg-primary span, .bg-primary i {
  color: var(--dark);
}

.bg-primary-darker {
  background-color: var(--primary-color-darker);
}

.placeholder-white::placeholder {
  color: #fff;
}

.underline-primary {
  -webkit-text-decoration-color: var(--primary-color) !important;
  text-decoration-color: var(--primary-color) !important;
  text-decoration: underline !important;
}

.border-primary {
  border-color: var(--primary-color);
}

.bg-primary .text-gray {
  color: #0e0e0ebf;
}

.bg-dark {
  background-color: var(--dark);
}

html {
  scroll-behavior: smooth;
  font-family: Poppins, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: "Baloo Tamma 2", cursive;
  font-weight: 800;
}

.text-6xl {
  margin-top: 30px;
}

.bg-primary-dark {
  background-color: var(--primary-color-dark);
}

.btn-primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .75rem;
  padding: .5rem 1rem;
  font-weight: 600;
}

.btn-primary:hover {
  cursor: pointer;
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.btn-primary {
  background-color: var(--primary-color);
}

.navbar-logo {
  height: 76px;
}

.navbar {
  z-index: 15000;
}

#navbar {
  background-color: #020e1400;
}

#illustration {
  z-index: -100;
  position: fixed;
  top: 0;
}

#illustration img {
  -o-object-fit: cover;
  object-fit: cover;
  aspect-ratio: initial;
  width: 100%;
  height: 100vh;
  position: fixed !important;
}

@media screen and (width >= 1200px) {
  #illustration img {
    -o-object-position: bottom;
    object-position: bottom;
  }
}

.navbar-mobile-menu-active {
  top: 0;
  position: fixed !important;
}

.menu-items a {
  font-size: 1.2rem;
}

.mobile-menu-items {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 15vh;
}

.mobile-menu-items a {
  margin-top: 28px;
  font-size: 2rem;
  font-weight: 600;
}

.text-primary {
  color: var(--primary-color);
}

.text-accent, .hover\:text-accent:hover {
  color: var(--accent-color);
}

.hover\:text-primary:hover {
  color: var(--primary-color);
}

.logo-container {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.logo-container h1 {
  font-size: 2rem;
  font-weight: 300;
}

.bg-primary {
  background: var(--primary-color);
}

.bg-accent {
  background: var(--accent-color);
}

.sphere {
  width: 600px;
}

.main-heading {
  text-transform: lowercase !important;
  font-size: 11rem !important;
  font-weight: 100 !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (width <= 600px) {
  .main-heading {
    font-size: 7rem !important;
    font-weight: 100 !important;
  }

  .sphere {
    width: 80%;
  }
}

.feature-1 {
  z-index: 10;
}

.feature-2 {
  background: var(--primary-color);
  opacity: .6;
  margin-top: -20px;
  margin-bottom: 80px;
  transform: rotate(350deg);
  margin-left: -10px !important;
}

.feature-3 {
  background: var(--accent-color);
  opacity: .6;
  margin-top: -20px;
  margin-bottom: 80px;
  transform: rotate(340deg);
  margin-left: -10px !important;
}

.mobile-menu {
  display: none;
}

.mobile-menu-active {
  backdrop-filter: blur(12px) saturate(30%) brightness(.5);
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: block;
  position: fixed;
  bottom: 0;
}

.darken {
  filter: brightness();
  z-index: -1;
}

.footer-social-icon {
  font-size: 5rem;
}

.big-slash {
  background: var(--dark);
  width: 10px;
  height: 80px;
  margin-top: 40px;
  margin-bottom: 40px;
  transform: rotate(45deg);
}

.divider {
  background-color: var(--primary-color);
  width: 500px;
  height: 2px;
}

.fa-star {
  color: var(--primary-color);
}

.text-primary-light {
  color: var(--primary-color-light);
}

.personal-mail {
  color: #fff !important;
  margin-top: 20px !important;
}

#stars-text {
  font-weight: bold;
}

#map {
  width: 100%;
  height: 400px;
}

#reviews {
  padding-bottom: 40px;
  overflow-x: auto;
}

#review-card {
  min-width: 400px;
}

@media screen and (width <= 600px) {
  html, body {
    overflow-x: hidden;
  }

  #illustration img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 80% 0;
    object-position: 80% 0;
    height: 100vh;
    margin-top: 0;
  }

  .navbar-logo {
    height: 60px;
  }

  html, body {
    scroll-padding-top: 92px !important;
  }

  #reviews {
    overflow-x: hidden;
  }

  #reviews > * {
    max-width: initial !important;
    width: 100% !important;
  }
}

.product-logo {
  margin-bottom: 24px;
  height: 120px !important;
}

.products {
  margin-top: 180px;
}

.products .own-product {
  text-align: center;
  flex-direction: column;
  display: flex;
}

.products .product-web {
  color: #fff;
  margin-bottom: 14px;
  text-decoration: underline;
}

.products img {
  margin: 0 auto 40px;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #07212c;
}

::-webkit-scrollbar-thumb {
  background: #b5ce45;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b5ce45cc;
}

.styled-form form {
  flex-direction: column;
  display: flex;
}

.styled-form form input, .styled-form form textarea {
  color: #fff;
  border-radius: .4rem;
  margin: 0 0 20px;
  padding: 10px;
}

.styled-form form input[type="submit"] {
  background-color: var(--primary-color-dark);
  color: #fff;
  border: none;
  border-radius: 1.5rem;
  margin: 0;
  padding: 10px 20px;
  font-weight: 600;
}

.styled-form form input:focus:not([type="submit"]), .styled-form form textarea:focus {
  outline: none;
  box-shadow: 2px 2px 6px 2px #0000004f;
}

.styled-form form label {
  margin-bottom: 5px;
}

.styled-form form input.touched:invalid {
  background-color: #faebeb;
  border: 3px solid #e58c8c;
}

.styled-form form:invalid input[type="submit"] {
  border: 2px dashed #888 !important;
}

.styled-form form:invalid input[type="submit"]:hover {
  background-position: 0 0 !important;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (width >= 768px) {
  .md\:inline {
    display: inline;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:scroll-py-8 {
    scroll-padding-top: 2rem;
    scroll-padding-bottom: 2rem;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (width >= 1024px) {
  .lg\:w-1\/2 {
    width: 50%;
  }
}

@media (width >= 1280px) {
  .xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
}
/*# sourceMappingURL=index.f6ca73b4.css.map */
